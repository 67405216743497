import React, { useMemo } from 'react';

import { dateAddToday } from '@utils/date';

import useCms from '@components/Cms/useCms';

import ConnectedMediaGridList from './ConnectedMediaGridList';

const EpisodeGridList = ({ hostId, categoryId, isNew, maxItems, ...rest }) => {
    const cms = useCms();
    const sinceMemo = useMemo(() => isNew && dateAddToday(-365), [isNew]);

    return (
        <ConnectedMediaGridList
            type="episode"
            hostId={hostId}
            categoryId={categoryId}
            size="large"
            title={isNew ? cms[2016] : cms[903]}
            isNew={isNew}
            since={sinceMemo}
            forcedMaxItem={maxItems || (isNew ? 6 : null)}
            {...rest}
        />
    );
};

export default EpisodeGridList;
