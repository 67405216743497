import React from 'react';

import useCms from '@components/Cms/useCms';

import ConnectedMediaGridList from './ConnectedMediaGridList';

const CategoryGridList = ({ ...rest }) => {
    const cms = useCms();

    return <ConnectedMediaGridList type="category" title={cms[904]} {...rest} />;
};

export default CategoryGridList;
