import React from 'react';

import { useListQuery } from '@utils/query';

import useCms from '@components/Cms/useCms';
import MediaGridList from '@components/Media/MediaGridList';
import useUser from '@components/User/useUser';

const ContinueListening = () => {
    const cms = useCms();
    const { user } = useUser();

    const [continueList, _continueList, _totalCount, _loadMore, _reload] = useListQuery({
        endpoint: `/media/continue`,
        muteNotifications: true,
        disabled: !user,
    });

    if (continueList?.length === 0) {
        return null;
    }

    return <MediaGridList mediaList={continueList} title={cms[556]} size="xLarge" forcedMaxItem={6} />;
};

export default ContinueListening;
