import React, { useMemo } from 'react';

import { dateAddToday } from '@utils/date';

import useCms from '@components/Cms/useCms';

import ConnectedMediaGridList from './ConnectedMediaGridList';

const PodcastsGridList = ({ isNew, byChildren, title, ...rest }) => {
    const cms = useCms();
    const sinceMemo = useMemo(() => isNew && dateAddToday(-45), [isNew]);

    return (
        <ConnectedMediaGridList
            type="podcast"
            title={title || (isNew ? (byChildren ? cms[2018] : cms[578]) : cms[581])}
            isNew={isNew}
            byChildren={byChildren}
            since={sinceMemo}
            {...rest}
        />
    );
};

export default PodcastsGridList;
