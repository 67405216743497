import React from 'react';

import { useListQuery } from '@utils/query';

import useCms from '@components/Cms/useCms';
import MediaGridList from '@components/Media/MediaGridList';

const Recommendations = () => {
    const cms = useCms();

    const [recommendationsList, _recommendationsList, _totalCount, _loadMore, _reload, _error, _pageInfo] =
        useListQuery({
            endpoint: `/media/recommendations`,
            muteNotifications: true,
        });

    if (recommendationsList?.length === 0) {
        return null;
    }

    return <MediaGridList mediaList={recommendationsList} title={cms[813]} />;
};

export default Recommendations;
