import React from 'react';

import { getLinks } from 'cache';

import MaxWidthBox from '@ui/MaxWidthBox';

import { fetchBanner, orderingPromoBanners } from '@components/api/serverApi';
import Banner from '@components/Banner';
import useCms from '@components/Cms/useCms';
import ContinueListening from '@components/ContinueListening';
import CategoryGridList from '@components/Media/gridLists/CategoryGridList';
import EpisodeGridList from '@components/Media/gridLists/EpisodeGridList';
import PodcastsGridList from '@components/Media/gridLists/PodcastsGridList';
import RadiosGridList from '@components/Media/gridLists/RadiosGridList';
import Recommendations from '@components/Recommendations/Recommendations';

const HomePage = ({ banners }) => {
    const cms = useCms();

    return (
        <MaxWidthBox>
            <Banner banners={banners} />
            <ContinueListening />
            <PodcastsGridList title={cms[4147]} maxItems={4} extended size="large" isHighlighted />
            <PodcastsGridList isNew byChildren linkToAll={getLinks('podcasts')} />
            {/* <EpisodeGridList isNew maxItems={4} extended /> */}
            <EpisodeGridList isLocked extended title={cms[4148]} />
            <PodcastsGridList isNew linkToAll={getLinks('podcasts')} />
            <RadiosGridList linkToAll={getLinks('radios')} />
            <Recommendations />
            <CategoryGridList linkToAll={getLinks('categories')} oneLiner size="small" />
        </MaxWidthBox>
    );
};

export const getServerSideProps = async () => {
    const bannerId = '281d584d-9882-47ab-80f3-acfac0bf43f0';
    const banners = await fetchBanner(bannerId);
    const orderedBanners = orderingPromoBanners(banners);

    return {
        props: {
            banners: orderedBanners || [],
        },
    };
};

export default HomePage;
