import React from 'react';

import useCms from '@components/Cms/useCms';

import ConnectedMediaGridList from './ConnectedMediaGridList';

const RadiosGridList = ({ isNew, ...rest }) => {
    const cms = useCms();

    return <ConnectedMediaGridList type="radio" title={isNew ? cms[579] : cms[583]} isNew={isNew} {...rest} />;
};

export default RadiosGridList;
